export const auth = {
    region : "eu-west-2",
    clientId : "2bnqnd0tqr86go4lkuqprvspcu",
    redirectUrl : "http://localhost:3000",
    identityProvider: "ForgeRock-tst" 
}

export const redirectUri =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000/auth/'
    : 'https://ukquals-elevate.pearsondev.tech/auth';

export const authDomainUrl = `https://auth.ukquals-elevate.pearsondev.tech`;

export const authUrl = `${authDomainUrl}/authorize?response_type=code&client_id=${auth['clientId']}&redirect_uri=${redirectUri}`;
export const tokenUrl = `${authDomainUrl}/oauth2/token`;

export const logoutUrl = `${authDomainUrl}/logout?client_id=${auth['clientId']}&logout_uri=${encodeURI(redirectUri)}`;