import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import {headerLogoName, primaryThemeColor} from "../../config/constants";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Footer() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
         position: "fixed",
          left:0,
          bottom:0,
          right:0,
          mt: 5

        }}
      >
        <CssBaseline />

        <Box
          component="footer"
          sx={{
            py: 1,
            px: 1,
            mt: 'auto',
            backgroundColor: primaryThemeColor,

          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body1" sx={{color: "#FFFFFF"}} align="center">
              Pearson
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}