import { Controller } from "react-hook-form";
import {TextField, InputLabel} from "@mui/material";
import { primaryThemeColor } from "../config/constants";

export const FormInputTextarea = (props) => {
  const { name, control, label, disabled, rows, placeholder  } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
      <>
        <InputLabel htmlFor={name + "-id"}  sx={{mt:2, color: primaryThemeColor}}>
            {label}
        </InputLabel>
        <TextField
        aria-label="htest"
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          //onChange={onChange}
          onChange={props.onChange ? (e) => props.onChange(e) : onChange}
          value={value}
          fullWidth
          //label="Summary"
          id={name + "-id"}
          inputProps={{
                name: name,
                id: name + "-id",
                "area-label": "test"
              }}
          placeholder={placeholder}
          variant="outlined"
          disabled={disabled}
          multiline
          rows={rows}
        />
      </>
      )}
    />
  );
};
