import { configureStore } from "@reduxjs/toolkit";
import caseReducer from "./reducers/caseReducer";
import userReducer from "./reducers/auth";
import centerNumberReducer from "./reducers/centerNumberReducer";

import storage from "redux-persist/lib/storage";
//import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  case: caseReducer,
  user: userReducer,
  centerNumbers: centerNumberReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

