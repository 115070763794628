import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Card, Grid, Typography, Divider, Stack } from "@mui/material";
import { setCurrentRole, setCredentials } from "../../redux/reducers/auth";
import { useEffect } from "react";

const ChooseRole = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user);
    const [searchParams, setSearchParams] = useSearchParams()
    const [roles, SetRoles] = React.useState([]);
    const [queues, SetQueues] = React.useState([]);

    useEffect(() => {
        console.log('userData', userData)
        if (userData.user.isAuthenticated) {
            SetRoles(JSON.parse(userData.user['role']));
            SetQueues(JSON.parse(userData.user['queues']));
          }
          else {
            navigate("/login");
          }
    }, [])

    const navigate = useNavigate()
    const handleClick = (role) => {
        dispatch(setCurrentRole(role))
        navigate(`/case-list`)
    }

    const getQueueForRole = (role) => {
        const queue = queues.find(queue => queue.role === role);
        if (queue) {
            return queue.queueNames;
        }
        return [];
    }

    if (roles.length === 1) {
        dispatch(setCurrentRole(roles[0]))
        navigate(`/case-list`)
        return;
    }

    return (
        <>
            <Box sx={{ display: 'flex', bgcolor: `#F0F0F0`, height: { xs: '100%', sm: "95vh" }, width: { xs: '100%' }, justifyContent: 'center', alignItems: 'center', }}>
                <Grid container spacing={2} sx={{ maxWidth: '100%', justifyContent: 'center' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', padding: '10px', }}>
                        <Typography className="detail-title" padding={'10px'} sx={{ textAlign: 'center' }}>
                            Your current contract with Pearson provides you with multiple roles to assume. Please choose one from below:
                        </Typography>
                    </Grid>
                    {roles.map((role, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            key={role}
                            sx={{
                                display: 'flex',
                                justifyContent: {
                                    xs: 'center',
                                    sm: index % 2 === 1
                                        ? 'flex-start'
                                        : roles[roles.length - 1] === role
                                            ? 'center'
                                            : 'flex-end',
                                },
                                alignItems: 'center',
                                width: '10vw',
                                padding: { xs: '10px', sm: '5px' }, 
                            }}
                        >
                            <Button className="button-text" onClick={() => handleClick(role)}>
                                <Card
                                    className="card-role-select"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        padding: '20px',
                                        width: { xs: '50vw', sm: '17vw', xl: "13vw" },
                                        aspectRatio: '1',
                                        textAlign: 'center',
                                        overflow: 'hidden', 
                                    }}
                                >
                                    {/* Title and Divider at the Top */}
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            marginBottom: '10px',
                                            flexShrink: 0, 
                                        }}
                                    >
                                        <Typography className="text-role-select" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                            {role}
                                        </Typography>
                                        <Divider sx={{ width: '100%', marginBottom: '10px' }} />
                                    </Box>
                                    {/* Centered Queues Below */}
                                    <Box
                                        sx={{
                                            flexGrow: 1, display: 'flex', alignItems: 'center', 
                                            justifyContent: 'center', overflow: 'auto', width: '100%',}}>
                                        <Stack 
                                            sx={{
                                                width: '100%', 
                                                padding: '10px', 
                                                maxHeight: '100%',
                                                overflow: 'auto', 
                                                textAlign: 'center',
                                            }}
                                        >
                                            {getQueueForRole(role).map((queue, queueIndex) => (
                                                <Typography key={queueIndex} className="text-role-queue">
                                                    {queue}
                                                </Typography>
                                            ))}
                                        </Stack>
                                    </Box>
                                </Card>
                            </Button>
                        </Grid>
                    ))}

                </Grid>
            </Box>
        </>
    )
}
export default ChooseRole;