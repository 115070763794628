/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import URLSearchParams from "@ungap/url-search-params";
import { setCredentials, logout } from "../../redux/reducers/auth";
import { useSearchParams } from "react-router-dom";
import { auth, redirectUri, tokenUrl } from "../../config/authDetails";
import axios from "axios";
import queryString from "query-string";
import CircularProgress from '@mui/material/CircularProgress';


import { getPayloadFromToken } from '../../helpers/utils';

function Auth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentRole = searchParams.get("role") || "marker";

  useEffect(() => {
    async function getToken() {
      const authCode = new URLSearchParams(window.location.search).get("code");
      if (authCode) {
        try {
          const { clientId } = auth;
          const data = {
            grant_type: "authorization_code",
            client_id: clientId,
            code: authCode,
            redirect_uri: redirectUri,
          };
          const configuration = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };

          const response = await axios.post(
            tokenUrl,
            queryString.stringify(data),
            configuration,
          );
          const { access_token, id_token, refresh_token } = response.data;
          console.log(response.data);
          const idTokenPayload = getPayloadFromToken(access_token);

          const {
            username,
            roles,
            queues,
            lastName,
            firstName,
            aaNumber
          } = idTokenPayload;

          const authUser = {
            idToken : id_token,
            accessToken : access_token,
            refreshToken : refresh_token,
            isAuthenticated : true,
            role : roles,
            userName : username,
            queues : queues,
            currentRole : (JSON.parse(roles))[0],
            lastName : lastName,
            firstName: firstName,
            aaNumber:aaNumber
          }
          dispatch(setCredentials(authUser));
          navigate("/");

        } catch (error) {
          console.log(`Error fetching auth tokens: ${error.message}`);
          dispatch(logout());
        }
      } else {
        navigate("/login");
      }
    }
    getToken();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        height="100%"
        sx={{
          width: "100%",
          "margin-top": "10%",
          "margin-bottom": "20%",
          }}
      >
       <CircularProgress />
      </Box>
    </Box>
  );
}

export default Auth;
