import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";

import ListCases from "../pages/cases/ListCases";
import CreateCase from "../pages/cases/CreateCase";
import CaseDetail from "../pages/cases/CaseDetail";
import CaseDetailSupervisor from "../pages/cases/CaseDetailSupervisor";
import CaseDetailInternal from "../pages/cases/CaseDetailInternal";
import CaseConfirmation from "../pages/cases/CaseConfirmation";
import NotFound from "../pages/common/NotFound";
import Login from "../pages/authentication/Login"
import Auth from "../pages/authentication/Auth"
import Alternate from "../pages/cases/alternate";
import CaseRouter from "../pages/cases/CaseRouter";
import ChooseRole from "../pages/authentication/ChooseRole";

const AppRoutes = () => {
  return (
    <>
          <Routes>
            <Route path="/" element={<ChooseRole />} />
            <Route path="/case-list" element={<ListCases />} />
            <Route path="/case-detail" element={<CaseRouter/>} />
            <Route path="/case-detail/:id" element={<Alternate/>} />
            <Route path="/create" element={<CreateCase />} />
            <Route path="/case-confirmation" element={<CaseConfirmation />} />
            <Route path="/*" exact={true} element={<NotFound />} />
            <Route path="/login" element={<Login/>} />
            <Route path="/auth" element={<Auth/>} />
          </Routes>
    </>
  );
};

export default AppRoutes;
