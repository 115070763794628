import React, { useEffect } from "react";
import {
  Card,
  Box,
  Avatar,
  Grid,
  CardContent,
  Typography,
  Divider,
  Alert,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ColorButton } from "../../helpers/utils";
import UserInfo from "./UserInfo";

function CaseConfirmation() {
  const navigate = useNavigate();
  const { state: createdCaseData } = useLocation();
  //const userData = useSelector((state) => state.user).user;

  window.history.replaceState({}, "");

  useEffect(() => {
    if (!createdCaseData) {
      return navigate("/");
    }
  }, []);

  return (
    <>
      <div>
        {createdCaseData && (
          <>
            <Grid
              container
              item
              spacing={2}
              xs={12}
              sm={12}
              md={12}
              sx={{ pr: 2, mt: 2, pl: 18 }}
            >
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                <b>Success!</b> Your case has been created. It will be auto
                assigned to supervisor and they will respond to you shortly.{" "}
                <Link to={`/case-detail/${createdCaseData.createdCaseReferenceNumber}`}>Click here</Link> to check case details.
              </Alert>
            </Grid>

            <Grid container item spacing={2} sx={{ pr: 2, pl: 18, mt: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography component="div" variant="h6">
                  Case #{createdCaseData.createdCaseReferenceNumber}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={5} sx={{ pr: 2, pl: 18, mt: 2 }}>
              <Grid item xs={12} sm={12} md={3}>
                <UserInfo
                  name={createdCaseData.fullName}
                  role={createdCaseData.currentRole}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={7}>
                <Card sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <List dense={false}>
                        <ListItem sx={{ pb: 0, pt: 0 }}>
                          <ListItemIcon sx={{ color: "inherit" }}>
                            <h4>Queue Name: &nbsp;&nbsp;&nbsp;</h4>
                          </ListItemIcon>
                          <ListItemText primary={createdCaseData.Queue} />
                        </ListItem>
                        <ListItem sx={{ pb: 0, pt: 0 }}>
                          <ListItemIcon sx={{ color: "inherit" }}>
                            <h4>Query Type: &nbsp;&nbsp;&nbsp;</h4>
                          </ListItemIcon>
                          <ListItemText
                            primary={createdCaseData.slectedQueryTypeLabel}
                          />
                        </ListItem>
                      </List>
                      <Typography
                        component="div"
                        variant="h4"
                        display="inline"
                        sx={{
                          pl: 2,
                          fontFamily: "inherit",
                          color: "inherit",
                          fontWeight: "bold",
                          fontSize: "inherit",
                        }}
                      >
                        Message:
                      </Typography>
                      <Divider />
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        sx={{ pl: 2 }}
                      >
                        {createdCaseData.CaseQuery}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>

                <Grid item sx={{ mt: 3, mb: 10 }}>
                  <ColorButton
                    onClick={() => navigate("/")}
                    variant="contained"
                    sx={{ ml: 0.5 }}
                  >
                    Back to Cases Queue
                  </ColorButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
}

export default CaseConfirmation;
