import * as yup from "yup";
//import { object, string, number, isSchema } from "yup";

import {
  visibleDocIdQueryType,
  visibleCenterNumberQueryType,
} from "../config/caseConfig";

const internalUserErrorMessage =
  "Please fill out qualification_type_title, qualification_id, paper_id or paper_title";

export const validationSchema = (userCurrentRole) =>
  yup.object({
    CaseQuery: yup
      .string()
      .required("This is required")
      .max(3000, "Detail must be less than 3000 characters"),
    Queue: yup.string().required("This is required"),
    QueryType: yup.string().required("This is required"),

    LearnerWorkInteractionType:
      userCurrentRole === "marker"
        ? yup.string().required("This is required")
        : yup.string().notRequired(),

    EPENDocId:
      userCurrentRole === "marker"
        ? yup.string().when(["QueryType", "LearnerWorkInteractionType"], {
            is: (QueryType, LearnerWorkInteractionType) => {
              return (
                LearnerWorkInteractionType === "EPEN" &&
                visibleDocIdQueryType.includes(QueryType)
              );
            },
            then: () =>
              yup
                .string()
                .required("This is required")
                .matches(/^[0-9]+$/, "This must be only number type")
                .min(
                  13,
                  "The attribute must have an integer value of 13 digits"
                )
                .max(20, "The attribute should not be greater than 20 digits"),
          })
        : yup.string().notRequired(),

    Centre:
      userCurrentRole === "marker"
        ? yup.string().when(["QueryType", "LearnerWorkInteractionType"], {
            is: (QueryType, LearnerWorkInteractionType) => {
              return (
                LearnerWorkInteractionType === "LWA" &&
                visibleCenterNumberQueryType.includes(QueryType)
              );
            },
            then: () => yup.string().required("This is required"),
          })
        : yup.string().notRequired(),

    assignToRole:
      userCurrentRole !== "marker"
        ? yup.string().required("This is required")
        : yup.string().notRequired(),
    assignToUser:
      userCurrentRole !== "marker"
        ? yup.string().required("This is required")
        : yup.string().notRequired(),



    availability_window_short_name:
      userCurrentRole === "internal"
        ? yup
            .string()
            .notRequired("This is required")
            // .matches(/^[0-9]+$/, "This must be only number type")
            // .min(4, "The attribute must have an integer value of 4 digits")
            // .max(4, "The attribute must have an integer value of 4 digits")
        : yup.string().notRequired(),


    qualification_type_title:
      userCurrentRole === "internal"
        ? yup.string().notRequired("This is required")
        : yup.string().notRequired(),
    qualification_id:
      userCurrentRole === "internal"
        ? yup.string().notRequired("This is required")
        : yup.string().notRequired(),
    paper_id:
      userCurrentRole === "internal"
        ? yup.string().notRequired("This is required")
        : yup.string().notRequired(),
    paper_title:
      userCurrentRole === "internal"
        ? yup.string().notRequired("This is required")
        : yup.string().notRequired(),
  });
