import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const StoreGate = ({ children }) => {
  const [isGateOpen, setIsGateOpen] = useState(false);
  const _persist = useSelector((state) => state._persist);

  useEffect(() => {
    setIsGateOpen(_persist.rehydrated);
  }, [_persist.rehydrated]);

  return <>{isGateOpen ? children : null}</>;
};

export default StoreGate;