import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from '@mui/material/CardHeader';
import { Button, Typography, Box, AppBar, Grid, Toolbar } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {auth, redirectUri, authUrl} from "../../config/authDetails";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';


export default function Login() {

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLoginClick = () => {
    window.location.assign(authUrl);
    setIsAuthenticating(true);
  }

  useEffect(() => {
    if (userData.user.isAuthenticated) {
      navigate("/");
      setIsAuthenticating(false);
    }
    else {
      window.location.assign(authUrl);
      setIsAuthenticating(true);
    }
  }, [userData]);


  return (
    <Grid container spacing={2} justify="center">
      <Grid item md={6} xs={12}/>
      <Grid item md={1} xs={12}
      sx={{
      width: "100%",
      "margin-top": "10%",
      "margin-bottom": "20%",
      height:"100%"
      }}>

        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </Grid>
      <Grid item md={5} xs={12}/>
  </Grid>
  );
}

