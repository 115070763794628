import React from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { Button, Typography, Box, AppBar, Grid, Toolbar } from "@mui/material";
import {createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Pagination from "@mui/material/Pagination";
import { ToggleButtonGroup } from "@mui/material";
import Casecard from "./Casecard";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const ListCases = () => {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = React.useState("disc");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const accessToken = localStorage.getItem('accessToken');
  const [soryBy, setSortBy] = React.useState('');
  const [currentUserType, setCurrentUserType] = useState("Marker");
  const [assign, setAssign] = React.useState(false);
  const [myCase, setMyCase] = React.useState(true);
  const [queue, setQueue] = React.useState(false);


  const [createdCaseList, setCreatedCaseList] = React.useState([
    {
      "CaseNumber" : 44444,
      "Assignee AA Number" : "123456",
      "Assignee Role" : "Supervisor",
      "Assignee Name" : "Sofia Conway",
      "Status": "Completed",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    },
    {
      "CaseNumber" : 2222,
      "Assignee AA Number" : "123456",
      "Assignee Role" : "Supervisor",
      "Assignee Name" : "Sofia Conway",
      "Status": "In Progress",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    },
    {
      "CaseNumber" : 4444,
      "Assignee AA Number" : "",
      "Assignee Role" : "",
      "Assignee Name" : "",
      "Status": "Not Assigned",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    }
  ])

  const [assignedCaseList, setAssignedCaseList] = React.useState([
    {
      "CaseNumber" : 44444,
      "Creator AA Number" : "123456",
      "Creator Role" : "Supervisor",
      "Creator Name": "Taylor Cross",
      "Status": "Completed",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    },
    {
      "CaseNumber" : 2222,
      "Creator AA Number" : "123456",
      "Creator Role" : "Supervisor",
      "Creator Name": "Taylor Cross",
      "Status": "In Progress",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample completed",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    },
    {
      "CaseNumber" : 4444,
      "Creator AA Number" : "123456",
      "Creator Role" : "Supervisor",
      "Creator Name": "Taylor Cross",
      "Status": "Completed",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    }
  ]);

  const [notAssignedList, setNotAssignedList] = React.useState([
    {
      "CaseNumber" : 44444,
      "Creator AA Number": "555123",
      "Creator Role": "Marker",
      "Status": "Not Assigned",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    },
    {
      "CaseNumber" : 2222,
      "Creator AA Number": "555123",
      "Creator Role": "Marker",
      "Status": "Not Assigned",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    },
    {
      "CaseNumber" : 4444,
      "Creator AA Number": "555123",
      "Creator Role": "Supervisor",
      "Status": "Not Assigned",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Progress",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    }
  ]);

  const [notAssignedListInternal, setNotAssignedListInternal] = React.useState([
    {
      "CaseNumber" : 44444,
      "Creator AA Number": "555123",
      "Creator Role": "Marker",
      "Creator Name": "Taylor Cross",
      "Status": "Not Assigned",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    },
    {
      "CaseNumber" : 2222,
      "Creator AA Number": "555123",
      "Creator Role": "Marker",
      "Creator Name": "Sofia Conway",
      "Status": "Not Assigned",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Sample request/feedback",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    },
    {
      "CaseNumber" : 4444,
      "Creator AA Number": "555123",
      "Creator Role": "Supervisor",
      "Creator Name": "Sofia Conway",
      "Status": "Not Assigned",
      "Queue Name" : "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
      "Query Type": "Progress",
      "Updated On": "Aug 10, 2024 20:24",
      "Created On": "Aug 10, 2024 20:24"
    }
  ]);

  const [sortListCase, setSortListCase] = React.useState(createdCaseList);

  const sortDataBy = (data, byKey) => {
    let sortedData;
    if(byKey == 'Status'){
      sortedData = data.sort(function(a,b){
        let x = a?.Status?.toLowerCase();
        let y = b?.Status?.toLowerCase();
        if(x>y){return 1;}
        if(x<y){return -1;}
        return 0;
      });
    }else{
      sortedData = data;
      // sortedData = data.sort(function(a,b){
      //   return a.age - b.age;
      // })
    }
    return sortedData;
  }

  const handleChange = (event) => {
    setSortBy(event.target.value);
    setSortListCase(sortDataBy(sortListCase,event.target.value))
  };

  useEffect(() => {
    console.log(userData)
    if (userData.user.isAuthenticated) {
      // const userQueryString = searchParams.get('currentUserType');
      setCurrentUserType(userData.user.currentRole);
      setSortListCase(createdCaseList);
    }
    else {
      navigate("/login");
    }
  }, []);

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 10,
      bottom: params.isLastVisible ? 0 : 10,
    };
  }, []);


  // const handleClick = () => {
  //   setFlag(!assign);
  // };

  const handleAssignedCasesClick = () => {
    setAssign(!assign);
    setMyCase(false);
    setQueue(false);
    setSortListCase(assignedCaseList);
  }

  const handleMyCasesClick = () => {
    setMyCase(!myCase);
    setAssign(false);
    setQueue(false);
    setSortListCase(createdCaseList);
  }

  const handleQueueClick = () => {
    setQueue(!queue);
    setAssign(false);
    setMyCase(false);
    if(currentUserType == "internal"){
      setSortListCase(notAssignedListInternal);
    }
    else {
      setSortListCase(notAssignedList);
    }

  }

  const handleBoxClick = () => {
    console.log('Grid click');
    navigate('/case-detail');
  }

  return (
    <>
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', padding: "5px", mt: 1, bgcolor: `#F0F0F0`}}> */}
      <Box sx={{ flexGrow: 1, bgcolor: `#F0F0F0` }}>
        {/* <ThemeProvider theme={caseListHeaderTheme}> */}
          {/* <AppBar position="static" color="primary"> */}
          <Toolbar>
            <Typography
              variant="h4"
              align="center"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Case List
            </Typography>
          </Toolbar>
          <Grid container>
            <Grid item md={2} lg={2} xs = {12}/>
            <Grid item md={8} lg={8} xs = {12} sx={{ marginBottom: "50px" }}>
              <Stack>
                <ToggleButtonGroup
                  value={type}
                  onChange={(event, newValue) => setType(newValue || undefined)}
                >

                 <Button
                    onClick={handleMyCasesClick}
                    size="large"
                    sx={{
                      border: "2px solid #003057",
                      backgroundColor:  myCase ? "#D4EAE4" : "#FFFFFF",
                      borderRadius: "10px",
                      width: "100%",
                      color: "#003057",
                    }}
                  >
                    My Created Case
                  </Button>

                  <Button
                    onClick={handleAssignedCasesClick}
                    size="large"
                    sx={{
                      color: "#003057",
                      border: "2px solid #003057",
                      borderRadius: "10px",
                      backgroundColor:  assign ? "#D4EAE4" : "#FFFFFF",
                      width: "100%",
                    }}
                  >
                    My Assigned Cases
                  </Button>

                  { !(currentUserType  == "Marker") &&

                  <Button
                    size="large"
                    onClick={handleQueueClick}
                    sx={{
                      color: "#003057",
                      border: "2px solid #003057",
                      backgroundColor:  queue ? "#D4EAE4" : "#FFFFFF",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  >
                    In Queue Cases
                  </Button>
                  }
                </ToggleButtonGroup>
              </Stack>
            </Grid>
            <Grid item md={2} xs = {12} lg={2}/>
          </Grid>


          <Grid container>
            <Grid item md={2} xs = {12} lg={2}>
              {/* <Button
                color="primary"
                size="large"
                sx={{
                  color: "#003057",
                  border: "2px solid #003057",
                  ml: 4,
                  width: "60%",
                  backgroundColor: "#FFFFFF",
                }}
              >
                Filters
              </Button> */}
            </Grid>

            <Grid item md={8} xs = {12} lg={8}>
              <Grid container spacing={2} xs = {12}>
                <Grid item md={10.5} lg={10.5}
                sx={{
                marginTop : "20px"
                }}>
                  SHOWING 25 of 25 CASES
                </Grid>

                <Grid item md={1.5} lg={1.5} justifyContent="flex-end" xs = {12}>

                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="soryBy-input-lable">SORT BY</InputLabel>
                    <Select
                      labelId="sortBy"
                      id="sortBy"
                      value={soryBy}
                      label="SORT BY"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Status"}>Status</MenuItem>
                      <MenuItem value={"Updated On"}>Updated On</MenuItem>
                      <MenuItem value={"Created On"}>Created On</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={12} lg={12} xs = {12}>
                <Link to={`/create`}>
                  <CardContent
                    sx={{
                      color: "#003057",
                      border: "2px solid #DCDCDC",
                      width: "100%",
                      "border-top": "20px solid #003057",
                      "border-radius": "10px",
                      "padding-top": "80px",
                      height: "200px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <AddBoxIcon /> Open New Case
                    </Typography>
                  </CardContent>
                  </Link>
                </Grid>

                {sortListCase.map((item, index) => (
                 <Grid item md={12} xs = {12} lg={12}
                 sx={{
                  cursor:"pointer"
                 }}
                 onClick={handleBoxClick}
                 >
                    <Casecard caseDetails={item} />
                  </Grid>
                ))}



                <Grid item md={12} lg={12} xs = {12}>
                  <Stack
                    alignItems="center"
                    sx={{
                      marginBottom: "80px",
                      marginTop: "30px",
                    }}
                  >
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      size="large"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} lg={2} xs = {12}></Grid>
          </Grid>

          {/* </AppBar> */}
        {/* </ThemeProvider> */}
      </Box>

      {
        // <DataGrid
        //   rows={caseList.case}
        //   columns={columns}
        //   initialState={{
        //     pagination: {
        //       paginationModel: { page: 0, pageSize: 5 },
        //     },
        //   }}
        //   sx={{
        //     [`& .${gridClasses.row}`]: {
        //       bgcolor: (theme) =>
        //         theme.palette.mode === "light" ? grey[200] : grey[900],
        //     },
        //     "& .MuiDataGrid-row": {
        //       borderTopColor: "white",
        //       borderTopStyle: "solid",
        //     }
        //   }}
        //   pageSizeOptions={[1, 10]}
        //   rowHeight={100}
        //   getRowSpacing={getRowSpacing}
        //   rowSpacingType="border"
        // />
      }
    </>
  );
};

export default ListCases;
