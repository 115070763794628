import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Divider,
  Tooltip,
  MenuItem,
  Button,
  Stack,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  headerLogoName,
  primaryThemeColor,
  headerMenu,
} from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/auth";
import { useSearchParams } from "react-router-dom";
import { auth, redirectUri, logoutUrl } from "../../config/authDetails";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { ArrowDropDown } from "@mui/icons-material";
function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const userType = useSelector((state) => state.userType);
  const [menuHeading, setMenuHeading] = useState(userType);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const darkTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: primaryThemeColor,
        light: "#42A5F5",
        dark: "#1565C0",
        contrastText: "#fff",
      },
    },
  });
  const dispatch = useDispatch();
  const handleClick = (event) => {
    navigate(`/`);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    searchParams.set("currentUserType", userType);
    setSearchParams(searchParams);
  }, [userType]);
  function logoutUser() {
    console.log("User logged out.");
    dispatch(logout());
    window.location.assign(logoutUrl);
  }
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Container maxWidth="100%">
            <Toolbar disableGutters variant="dense">
              <Grid container alignItems="center">
                {/* User Info Section */}
                <Grid item md={5} xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, alignItems: 'center' }}>
                  
                  {userData.user.isAuthenticated && (
                    <>
                      <Typography sx={{ mr: 1, fontSize: { xs: 12, md: 14 } }}>
                        {userData.user['firstName'] + " " + userData.user['lastName']}
                      </Typography>
                      <Divider orientation="vertical" flexItem sx={{ height: { xs: '16px', md: '1.25vw' }, bgcolor: '#505759', alignSelf: 'center' }} />
                      <Typography sx={{ mr: 1, ml: 1, fontSize: { xs: 12, md: 14 } }}>
                        638459
                      </Typography>
                      <Divider orientation="vertical" flexItem sx={{ height: { xs: '16px', md: '1.25vw' }, bgcolor: '#505759', alignSelf: 'center' }} />
                      <Typography sx={{ mr: 1, ml: 1, fontSize: { xs: 12, md: 14 } }}>
                        {userData.user['currentRole']}
                      </Typography>
                      {JSON.parse(userData.user.role).length > 1 && 
                      <>
                      <Divider orientation="vertical" flexItem sx={{ height: { xs: '16px', md: '1.25vw' }, bgcolor: '#505759', alignSelf: 'center' }} />
                      <Button onClick={handleClick}
                      sx={{ mr: 1, ml: 1, fontSize: { xs: 12, md: 14 }, textTransform: 'none', color: "white" }}>
                        Switch Role
                      </Button>
                      </>
                      }
                    </>
                  )}
                </Grid>
                {/* Logo Section */}
                <Grid item md={2} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                      display: "flex",
                      fontFamily: "monospace",
                      fontWeight: 700,
                      letterSpacing: ".1rem",
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: { xs: 16, md: 20 },
                    }}
                  >
                    {headerLogoName}
                  </Typography>
                </Grid>
                {/* Logout Section */}
                <Grid item md={5} xs={12} sx={{ display: 'flex', justifyContent: {xs: "center", sm:'flex-end'}, alignItems: 'center', paddingRight: { xs: 1, md: 3 } }}>
                  {userData.user.isAuthenticated && (
                    <Typography
                    variant="h6"
                    onClick={logoutUser}
                    noWrap
                    component="a"
                    sx={{
                      display: { xs: "flex", md: "flex" },
                      fontFamily: "monospace",
                      fontWeight: 700,
                      letterSpacing: ".1rem",
                      color: "inherit",
                      textDecoration: "none",
                      textAlign: "right",
                      cursor: "pointer",
                    }}
                  >
                    LOGOUT
                  </Typography>
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    </Stack>
  );
}
export default Header;