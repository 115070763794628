import React from "react";
import Box from "@mui/material/Box";

import { BrowserRouter } from 'react-router-dom';
import Header from "./pages/common/Header";
import Footer from "./pages/common/Footer";
// import ListCases from "./pages/cases/ListCases";
// import CreateCase from "./pages/cases/CreateCase";
// import CaseDetail from "./pages/cases/CaseDetail";
// import CaseDetailSupervisor from "./pages/cases/CaseDetailSupervisor";
// import CaseDetailInternal from "./pages/cases/CaseDetailInternal";
// import CaseConfirmation from "./pages/cases/CaseConfirmation";
// import NotFound from "./pages/common/NotFound";
// import Login from "./pages/authentication/Login"
// import Auth from "./pages/authentication/Auth"
import AppRoutes from './router/AppRoutes';

const App = () => {
  return (
    <>
      <Box sx={{ bgcolor: `#F0F0F0` }}>
      <BrowserRouter>
        <Header />
        <AppRoutes />
        <Footer />
        </BrowserRouter>
      </Box>

    </>
  );
};

export default App;
