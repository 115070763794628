/* eslint-disable array-callback-return */
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { primaryThemeColor } from "../config/constants";
import { rolesHierarchy } from "../config/caseConfig";

export const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: primaryThemeColor,
  "&:hover": {
    backgroundColor: primaryThemeColor,
  },
}));

export const filterByValue = (array, string) => {
  const filteredObject = array.filter((o) =>
    Object.keys(o).some((k) =>
      o[k].toLowerCase().includes(string.toLowerCase())
    )
  );
  return filteredObject[0].value || filteredObject;
};

export const transformQueuesData = (queuesData, currentRole) => {
  const queuesResult = [];
  let data = [];
  queuesData.map((row) => {
    if (row.role === currentRole?.toLowerCase()) {
      data = row.queueNames;
    }
  });
  data.map((row) => {
    queuesResult.push({ key: row, value: row });
  });
  return queuesResult;
};

export const transformRolesData = (data) => {
  const result = [];
  for (const row in data) {
    result.push({ key: row, value: row.toUpperCase() });
  }
  return result;
};

const getAgentCustomerData = (
  currentRoleLevel,
  assigneeRoleLevel,
  currentUserData,
  data
) => {
  const { assignToRole, assignToUser } = data;
  const { currentRole, firstName, lastName, currentUserAAID } = currentUserData;
  let updateData = {};
  if (currentRoleLevel < assigneeRoleLevel) {
    // If caller_role higher in hierarchy than the assignee role
    updateData = {
      caseAgentRole: currentRole,
      caseCustomerRole: assignToRole,

      caseAgentName: `${firstName} ${lastName}`,
      caseCustomerName: assignToUser.split("##")[1],

      caseAgentAAID: currentUserAAID || "fetch from store",
      caseCustomerAAID: assignToUser.split("##")[0],
    };
  } else {
    //If caller_role lower or same in hierarchy than the assignee role:
    updateData = {
      caseAgentRole: assignToRole,
      caseCustomerRole: currentRole,

      caseAgentName: assignToUser.split("##")[1],
      caseCustomerName: `${firstName} ${lastName}`,

      caseAgentAAID: assignToUser.split("##")[0],
      caseCustomerAAID: currentUserAAID || "fetch from store",
    };
  }
  return updateData;
};

export const transformCreateCasePayload = (data, currentUserData) => {
  let payload = {};
  if (currentUserData.currentRole !== "marker") {
    const currentRoleLevel = rolesHierarchy.filter(
      (roleRow) => roleRow.role === currentUserData.currentRole.toLowerCase()
    )[0].level;

    const assigneeRoleLevel = rolesHierarchy.filter(
      (roleRow) => roleRow.role === data.assignToRole.toLowerCase()
    )[0]?.level;

    payload = getAgentCustomerData(
      currentRoleLevel,
      assigneeRoleLevel,
      currentUserData,
      data
    );
  }

  //const slectedQueryTypeLabel = filterByValue(queryTypeData, data.QueryType);
  payload = { ...payload, ...data };

  payload = removeEmptyValues(payload);
  console.log("final payload:::", payload);
  return payload;
};

export const removeEmptyValues = (object) =>
  Object.fromEntries(Object.entries(object).filter(([_, value]) => value));

/**
 * Retrieve payload from token
 * @param token Token
 * @returns JSON payload
 */
export function getPayloadFromToken(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function capitalizeFirstLetter(string) {
  return string ? string?.charAt(0).toUpperCase() + string?.slice(1) : "";
}
