import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { primaryThemeColor } from "../config/constants";

export const FormInputDropdown = (props) => {
  const { name, control, label, options, defaultEmptyOption } = props;
  const generateSingleOptions = () => {
    if (options.length > 0) {
      return options.map((option) => {
        return (
          <MenuItem
            key={option.key}
            value={option.key}
            id={option.key}
            aria-hidden="false"
            sx={{ whiteSpace: "normal", wordWrap: "break-all" }}
          >
            {option.value}
          </MenuItem>
        );
      });
    } else {
      <MenuItem aria-hidden="false">{"Please Select"}</MenuItem>;
    }
  };
  return (
    <>
      <InputLabel
        htmlFor={name + "-id"}
        sx={{ mt: 2, color: primaryThemeColor }}
      >
        {label}
      </InputLabel>

      <Controller
        sx={{ maxWidth: "100%" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              onChange={props.onChange ? (e) => props.onChange(e) : onChange}
              error={error && !value ? !!error : false}
              fullWidth
              displayEmpty
              value={value}
              id={name}
              aria-label={name}
              inputProps={{
                name: name,
                id: name + "-id",
              }}
              aria-labelledby={name}
              MenuProps={{ sx: { width: "50%" } }}
            >
              <MenuItem
                value=""
                sx={{ whiteSpace: "normal", wordWrap: "break-all" }}
              >
                <em>{defaultEmptyOption}</em>
              </MenuItem>
              {generateSingleOptions()}
            </Select>
            <FormHelperText error>
              {error && !value ? error.message : null}
            </FormHelperText>
          </>
        )}
        control={control}
        name={name}
      />
    </>
  );
};
