import axiosInstance from "./http";
import { store } from "../redux/store";
import { otherAPIBaseUrl } from "../config/constants";
import { removeEmptyValues } from "./utils";

const stateData = store.getState();
const currentUserRole = stateData?.user?.user?.currentRole?.toLowerCase();
const userToken = stateData?.user?.user?.accessToken;

console.log('userToken::::', stateData?.user?.user?.accessToken)

export const fetchUsersList = async (searchTerm) => {
  try {
    const result = await axiosInstance.get(
      `https://dummyjson.com/products/search?q=${searchTerm}`
    );
    const updatedOptions = result?.data?.products.map((p) => {
      return { id: p.title.replace(/ /g, "_"), label: p.title };
    });
    return updatedOptions;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchCenters = async (searchTerm) => {
  try {
    const result = await axiosInstance.get(`${otherAPIBaseUrl}/centre?search_term=${searchTerm}&offset=0&limit=100`,{ headers: { Authorization: `Bearer ${userToken}`, "Caller-Role":  currentUserRole }});
    let updatedResponse = [];
    result?.data?.data.map((p) => {
      updatedResponse.push({ id: p.centre_id, label: p.centre_name });
    });
    return updatedResponse;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchInternalUserQueues = async (searchTerm) => {
  try {
    const {
      availability_window_short_name,
      qualification_type_title,
      qualification_id,
      paper_id,
      paper_title,
    } = searchTerm;

    let params = {
      availability_window_short_name,
      qualification_type_title,
      qualification_id,
      paper_id,
      paper_title,
      limit: 100,
    };
    params = removeEmptyValues(params);
    params = { ...params, offset: 0 };
    console.log(params);
    //const url = `${otherAPIBaseUrl}/product?paper_title=${paper_title}&offset=0&limit=100`;
    const url = `${otherAPIBaseUrl}/product?paper_title=ARABIC&offset=0&limit=100`;
    //const url = `${otherAPIBaseUrl}/product/`;
    const queuesResult = [];

    const result = await axiosInstance.get(`${otherAPIBaseUrl}/product`,{ headers: { Authorization: `Bearer ${userToken}`, "Caller-Role":  currentUserRole }, params});



    result.data.data.map((row) => {
      queuesResult.push({ key: row.queue_name, value: row.queue_name });
    });
    console.log('queuesResult:::', queuesResult);

    return queuesResult;
  } catch (error) {
    console.log(error);
    return error;
  }
};
