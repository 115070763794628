import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCenterNumbersList = createAsyncThunk(
  "fetchCenterNumbersList",
  async () => {
    const { data } = await axios.get(
      "https://dummyjson.com/c/d2ec-c2b9-4774-9151"
    );
    const result = [];
    data.forEach((value) => {
      result.push({
        id: value.centerNumber,
        label: value.centerName,
      });
    });
    return result;
  },
  {
    condition: (centerNumber, { getState, extra }) => {

      const { centerNumbers } = getState();
      if (centerNumbers.list.length > 0) {
        // Already fetched or in progress, don't need to re-fetch
        console.log("centerNumbers Data already fetched. Access from store.");
        return false;
      }
    },
  }
);

const initialState = {
  list: [],
  isLoading: false,
  isError: false,
};

export const centerNumberSlice = createSlice({
  name: "centerNumberData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Listing of User Data
    builder.addCase(fetchCenterNumbersList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCenterNumbersList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
    });
    builder.addCase(fetchCenterNumbersList.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export default centerNumberSlice.reducer;
