export const headerLogoName = "ASSOCIATE CONNECT";
export const primaryThemeColor = "#003057";

export const headerMenu = [
  {
    href: "/my-cases",
    label: "My Cases",
  },
];

export const apiBaseUrl = 'https://api-tst.ukquals-elevate.pearsondev.tech';

export const otherAPIBaseUrl = "https://29za13ach7.execute-api.eu-west-2.amazonaws.com/TST";

export const errorMessages = {
  centerAPIFail: "There is some issue to fetch centers.",
  centerAPIEmptyResponse: "There is no centers for this keyword."
}