import { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Alert,
  Autocomplete,
  TextField,
  InputLabel,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FormInputTextarea } from "../../form-components/FormInputTextarea";
import { FormInputDropdown } from "../../form-components/FormInputDropdown";
//import { ComboBox } from "../../form-components/ComboBox";
import { validationSchema } from "../../validations/caseCreate";
import { useYupValidationResolver } from "../../validations/validationResolver";
import { createCase } from "../../redux/reducers/caseReducer";
import { ROLES } from "../../config/permission-maps";

import Userinfo from "./UserInfo";
import { errorMessages, primaryThemeColor } from "../../config/constants";
import {
  defaultValues,
  learnerWork,
  markerQueryTypeData,
  otherUserQueryTypeData,
  visibleDocIdQueryType,
  visibleCenterNumberQueryType,
} from "../../config/caseConfig";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  ColorButton,
  filterByValue,
  transformCreateCasePayload,
  transformQueuesData,
  transformRolesData,
} from "../../helpers/utils";
import {
  fetchInternalUserQueues,
  fetchUsersList,
  fetchCenters,
} from "../../helpers/case.services";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryThemeColor,
    },
  },
});

function CreateCase() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const rolesData = transformRolesData(ROLES);
  const [learnerWorkSelected, setLearnerWork] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //const [assignToUserSelected, setAssignToUserSelected] = useState(null);
  const [showDocId, setShowDocId] = useState(false);
  const [showCenterNumber, setShowCenterNumber] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [centersList, setCentersList] = useState([]);
  const [internalQueues, setInternalQueues] = useState([]);
  //const [centerNumberSelected, setCenterNumber] = useState("");
  //const [queryTypeSelected, setQueryTypeSelected] = useState("");

  let queuesData = useSelector((state) => state?.user?.user?.queues);

  //let currentRole = useSelector((state) => state.user.user.currentRole);
  //let currentUserName = useSelector((state) => state.user.user.userName);

  let {
    currentRole,
    userName: currentUserName,
    firstName,
    lastName,
  } = useSelector((state) => state.user.user);

  currentRole = searchParams.get("currentUserType") || currentRole;

  const resolver = useYupValidationResolver(validationSchema(currentRole));
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: defaultValues,
    resolver,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  let queuesResult =
    queuesData && currentRole !== "internal"
      ? transformQueuesData(JSON.parse(queuesData), currentRole)
      : [];

  const queryTypeData =
    currentRole?.toLowerCase() === "marker"
      ? markerQueryTypeData
      : otherUserQueryTypeData;

  const onSubmit = async (data) => {
    setShowLoader(true);
    const currentUserData = { currentRole, firstName, lastName };
    const createCasePayload = transformCreateCasePayload(
      data,
      //queryTypeData,
      currentUserData
    );

    delete createCasePayload?.availability_window_short_name;
    delete createCasePayload?.qualification_type_title;
    delete createCasePayload?.qualification_id;
    delete createCasePayload?.paper_id;
    delete createCasePayload?.paper_title;

    await dispatch(createCase(createCasePayload)).then((response) => {
      window.scrollTo(0, 0);
      if (response.error) {
        setErrorMessage(response?.payload?.message);
        setShowLoader(false);
      } else {
        reset();
        setShowLoader(false);
        const createdCaseReferenceNumber = response.payload.reference_number;
        const slectedQueryTypeLabel = filterByValue(
          queryTypeData,
          data.QueryType
        );

        const caseData = {
          ...createCasePayload,
          slectedQueryTypeLabel,
          currentRole,
          fullName: `${firstName} ${lastName}`,
          createdCaseReferenceNumber,
        };

        navigate("/case-confirmation", { state: caseData });
      }
    });
  };

  const onReset = () => {
    reset();
    navigate("/");
  };

  const onQueryTypeChange = (event) => {
    setValue("QueryType", event.target.value);

    learnerWorkSelected === "EPEN" &&
    visibleDocIdQueryType.includes(event.target.value)
      ? setShowDocId(true)
      : setShowDocId(false);

    learnerWorkSelected === "LWA" &&
    visibleCenterNumberQueryType.includes(event.target.value)
      ? setShowCenterNumber(true)
      : setShowCenterNumber(false);
  };

  const onLearnerWorkChange = (event) => {
    setLearnerWork(event.target.value);
    setValue("LearnerWorkInteractionType", event.target.value);
    event.target.value === "LWA"
      ? setShowDocId(false)
      : setShowCenterNumber(false);
  };

  const onCenterChange = async (event) => {
    if (event?.target?.value && event?.target?.value.length >= 3) {
      try {
        const centersData = await fetchCenters(event.target.value);
        if (centersData.code === "ERR_NETWORK") {
          setErrorMessage(errorMessages.centerAPIFail);
          setCentersList([]);
        } else {
          setErrorMessage("");
          setCentersList(centersData);
        }
        console.log("centersData:::", centersData, centersData.code);
      } catch (error) {
        console.log("center Number API fail::", error);
        setCentersList([]);
        setErrorMessage(errorMessages.centerAPIFail);
      }
    } else {
      setCentersList([]);
    }
  };

  const onAssignToUserChange = async (event) => {
    if (event?.target?.value && event?.target?.value.length >= 3) {
      const userListData = await fetchUsersList(event.target.value);
      setUsersList(userListData);
    } else {
      setUsersList([]);
    }
  };

  const validateInternalUserQueues = async (event, fieldName) => {
    setValue(fieldName, event.target.value);
    const {
      availability_window_short_name,
      qualification_type_title,
      qualification_id,
      paper_id,
      paper_title,
    } = getValues();

    if (
      availability_window_short_name ||
      qualification_type_title ||
      qualification_id ||
      paper_id ||
      paper_title
    ) {
      const qData = await fetchInternalUserQueues({
        availability_window_short_name,
        qualification_type_title,
        qualification_id,
        paper_id,
        paper_title,
      });
      setInternalQueues(qData);
      queuesResult = qData;
    }
  };

  return (
    <>
      {showLoader && (
        <Backdrop className={"classes.backdrop"} open sx={{ color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <ThemeProvider theme={theme}>
        <Grid container item spacing={1} sx={{ pr: 2, pl: 18, mt: 2 }}>
          <Grid item xs={10} sm={10} md={10}>
            <Typography
              color="primary"
              variant="h1"
              sx={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              Open a Case
            </Typography>
          </Grid>
        </Grid>

        <Grid container item spacing={1} sx={{ pr: 2, pl: 18, mt: 2 }}>
          <Grid item xs={12} md={3}>
            <Userinfo name={`${firstName} ${lastName}`} role={currentRole} />
          </Grid>
          <Grid item xs={12} sm={12} md={7} sx={{ pl: 40, mb: 20 }}>
            <Card>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  {errorMessage && (
                    <Alert severity="error">
                      <b>Error!</b> {errorMessage}
                    </Alert>
                  )}

                  {currentRole === "internal" && (
                    <>
                      <Grid container item spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormInputTextarea
                            name="availability_window_short_name"
                            control={control}
                            label="Availability Window Short Name *"
                            //placeholder="Availability Window Short Name"
                            onChange={(e) =>
                              validateInternalUserQueues(
                                e,
                                "availability_window_short_name"
                              )
                            }
                            rows={1}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <FormInputTextarea
                            name="qualification_type_title"
                            control={control}
                            label="Qualification Type Title *"
                            rows={1}
                            onChange={(e) =>
                              validateInternalUserQueues(
                                e,
                                "qualification_type_title"
                              )
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container item spacing={1}>
                        <Grid item xs={12} sm={12} md={4}>
                          <FormInputTextarea
                            name="qualification_id"
                            control={control}
                            label="Qualification Id *"
                            rows={1}
                            onChange={(e) =>
                              validateInternalUserQueues(e, "qualification_id")
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                          <FormInputTextarea
                            name="paper_id"
                            control={control}
                            label="Paper Id *"
                            rows={1}
                            onChange={(e) =>
                              validateInternalUserQueues(e, "paper_id")
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <FormInputTextarea
                            name="paper_title"
                            control={control}
                            label="Paper Title *"
                            rows={1}
                            onChange={(e) =>
                              validateInternalUserQueues(e, "paper_title")
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <FormInputDropdown
                    name="Queue"
                    control={control}
                    label="Queue *"
                    options={
                      currentRole !== "internal" ? queuesResult : internalQueues
                    }
                    defaultEmptyOption="- Select -"
                    aria-label="Queue"
                  />

                  {currentRole === "marker" && (
                    <FormInputDropdown
                      name="LearnerWorkInteractionType"
                      control={control}
                      label="Select EPEN or LWA *"
                      defaultEmptyOption="In relation to this product, please select how you interact with the learner work:"
                      options={learnerWork}
                      onChange={(e) => onLearnerWorkChange(e)}
                    />
                  )}
                  <FormInputDropdown
                    name="QueryType"
                    control={control}
                    label="Query Type *"
                    options={queryTypeData}
                    defaultEmptyOption="Please select a query type"
                    //value={queryTypeSelected}
                    onChange={onQueryTypeChange}
                  />

                  {showDocId && currentRole === "marker" && (
                    <FormInputTextarea
                      name="EPENDocId"
                      control={control}
                      label="Doc ID *"
                      placeholder="Please input an EPEN DOC ID"
                      rows={1}
                    />
                  )}

                  {showCenterNumber && currentRole === "marker" && (
                    <>
                      <InputLabel shrink={false}>
                        <Typography
                          className={"classes.label"}
                          sx={{ mt: 2, color: primaryThemeColor }}
                        >
                          Center Number *
                        </Typography>
                      </InputLabel>
                      <Autocomplete
                        control={control}
                        name="Centre"
                        options={centersList}
                        filterOptions={(centersList) => centersList || []}
                        label={"Center Number *"}
                        onInputChange={onCenterChange}
                        onChange={(event, values) => {
                          if (values) {
                            setValue("Centre", `${values?.id}`);
                          }
                        }}
                        selectOnFocus
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="- Select Center -"
                            label="- Select center -"
                            variant="outlined"
                          />
                        )}
                      />
                      <FormHelperText error>
                        {errors?.Centre ? errors?.Centre.message : null}
                      </FormHelperText>
                    </>
                  )}

                  <FormInputTextarea
                    name="CaseQuery"
                    control={control}
                    label="Summary *"
                    placeholder="Describe your issue or question here. Cite relevant documents in detail."
                    multiline
                    rows={5}
                  />

                  {currentRole !== "marker" && (
                    <>
                      <FormInputDropdown
                        name="assignToRole"
                        control={control}
                        label="Assign to role: *"
                        options={rolesData || []}
                        defaultEmptyOption="- Select -"
                        aria-label="AssignToRole"
                      />

                      <InputLabel shrink={false}>
                        <Typography
                          className={"classes.label"}
                          sx={{ mt: 2, color: primaryThemeColor }}
                        >
                          Assign to user *
                        </Typography>
                      </InputLabel>
                      <Autocomplete
                        control={control}
                        name="assignToUser"
                        options={usersList}
                        label={"Assign to user *"}
                        onInputChange={onAssignToUserChange}
                        onChange={(event, values) => {
                          if (values) {
                            setValue(
                              "assignToUser",
                              `${values?.id}##${values?.label}`
                            );
                          }
                        }}
                        //value={assignToUserSelected}
                        //inputValue={assignToUserSelected}
                        selectOnFocus
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="- Select user -"
                            label="- Select user -"
                            variant="outlined"
                          />
                        )}
                      />
                      <FormHelperText error>
                        {errors?.assignToUser
                          ? errors?.assignToUser.message
                          : null}
                      </FormHelperText>
                    </>
                  )}
                  <Grid
                    justify="flex-end"
                    alignItems="center"
                    container
                    sx={{ mt: 1 }}
                  >
                    <Grid item>
                      <Button
                        onClick={() => reset(onReset)}
                        variant="outlined"
                        sx={{
                          borderColor: primaryThemeColor,
                          border: 1,
                          mr: 0.5,
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <ColorButton
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        sx={{ ml: 0.5 }}
                      >
                        Submit
                      </ColorButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

export default CreateCase;
