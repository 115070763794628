import React, { useRef, useState, useEffect } from "react";
import {
    Grid, Typography, Box, Divider, Paper, Card,
    Button, TextField, IconButton,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Select, InputLabel, FormControl, MenuItem
  } from '@mui/material';
import { ArrowBack, ArrowDropDown, ArrowDropUp, ArrowForward, ArrowLeft, ArrowUpward, BookmarkBorderOutlined, Send } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Alternate(detailDict, additionalDetails, orgQuery, notes) {
    const [gridSize, setGridSize] = useState(12);
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState(false)
    const [expandButton, setExpandButton] = useState("Show More")
    const [showNotes, setShowNotes] = useState(false)
    const [notesButton, setNotesButton] = useState("Show Notes")
    const [open, setOpen] = useState(false);
    const [release, setRelease] = useState(false);
    const boxARef = useRef(null)
    const boxBRef = useRef(null)

    const handleClickEscalate = () => {
        setOpen(!open);
    };
    const handleClickRelease = () => {
        setRelease(!release);
    };

    useEffect(() => {
        if (showNotes) {
            setGridSize(8)
            return
        }

        setGridSize(12)
    }, [showNotes])

    const handleExpand = () => {
        setExpanded(!expanded)
        if (expanded) {
            setExpandButton("Show More")
            return
        }

        setExpandButton("Hide")
    }

    const handleNotes = () => {
        setShowNotes(!showNotes)

        if (showNotes) {
            setNotesButton("Show Notes")
            return
        }

        setNotesButton("Hide")
    }

    const rteRef = useRef(null);
    const queueName = "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)"
    detailDict = {
        "Queue Name": "2406^GCSE (9-1)^1AA0_1F^ARABIC LISTENING (F)",
        "Query Type": "Malpractice",
        "Created On": "Aug 10, 2024 20:24",
        "Updated On": "Aug 10, 2024 20:24",
        "Status": "In Progress",
        "Creator AA Number": "095343",
        "Create Role": "Marker",
        "Asignee AA Number": "43219",
        "Asignee Role" : "Supervisor"
    }
    const detailLength = Object.entries(detailDict).length + 1

    notes = [
        'Customer issue may be resolved with future product release',
        `Yes, but let's hold off on mentioning it until publically available`
    ]

    additionalDetails = {
        "Centre Number": "212323323",
        "Learner Work Interaction Type": "Learner Work Assessment",
    }
    const addlLength = Object.entries(additionalDetails).length + 1


    orgQuery = [
        `Hi Supervisor,\n
                  I’m new to marking on this unit this year. I wonder if you could take a look at a script that I’m unclear about (Doc ID _0524001015394). I think this is a really good response to Q3 but I remember it was said at the Standardisation Meeting that there has to be full focus on the question for a response to get into Level 5. I’d be inclined to give this script 20,  but the candidate has spent time talking about Hamlet as a tragic hero. Would this limit the mark I can give it?
                  \n–Rhaenyra Targaryen, 638459`,
        "Hi Rhaenyra, ..."
    ]

    return (
        <>
        {/* Main content */}
        <Box sx={{bgcolor: `#F0F0F0`, height: '100vh', width: '100vw' }}>
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: "5px", mt: 1, }}>
                    <Grid container spacing={1} sx={{ mr: { xs: 2, sm: 4 }, ml: { xs: 2, sm: 4 } }}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={() => { navigate('/') }}>
                                <Typography className="navigator">VIEW WORK QUEUE</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
            <Paper elevation={3} sx={{ ml: { xs: 2, sm: 5 }, mr: { xs: 2, sm: 5 }, mt: 1, mb: 10 }}>
                <Box sx={{ backgroundColor: '#003057', padding: '10px 24px', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                </Box>
                {/* Case Header */}
                <Box sx={{ backgroundColor: '#FFFFFF', padding: '15px', ml: { xs: 2, sm: 8 }, display: 'flex', alignItems: 'center' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-start', }}>
                            <Typography className="detail-title" mr={2}>
                                Case 1234 – Malpractice
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                            <Button variant='text' className="button-text" onClick={handleClickEscalate}>
                                Escalate
                            </Button>
                            <Divider orientation="vertical" flexItem sx={{ height: '25%', alignSelf: 'center' }} />
                            <Button variant='text' className="button-text" onClick={handleClickRelease}>
                                Release
                            </Button>
                            <Divider orientation="vertical" flexItem sx={{ height: '25%', alignSelf: 'center' }} />
                            <Button variant='text' className="button-text">
                                Resolve
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-start', }}>
                            <Typography className="detail-key" gutterBottom mr={2}>
                                {queueName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Box ref={boxARef} sx={{ backgroundColor: '#FFFFFF', padding: '15px', ml: { xs: 2, sm: 8 }, display: "flex" }}>
                            <Grid container spacing={2}>
                                {Object.entries(detailDict).map(([key, value]) => (
                                    <Grid item xs={12} sm={6} md={6} key={key}>
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography className="detail-key" align="center" sx={{ fontWeight: 'bold' }} key={key + "text"}>
                                            {key}
                                        </Typography>
                                        </Box>
                                        <Divider sx={{ mt: 0.5, mb: 1 }} key={key + "divider"} />
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography className="detail-value" align="center" key={key + "bText"}>
                                            {value}
                                        </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Button className="button-text" onClick={handleExpand}>{expandButton}</Button>
                                    </Box>
                                </Grid>
                                {expanded && Object.entries(additionalDetails).map(([key, value]) => (
                                    <Grid item xs={12} sm={6} md={6} key={key}>
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography className="detail-key" align="center" sx={{ fontWeight: 'bold' }} key={key + "text"}>
                                            {key}
                                        </Typography>
                                        </Box>
                                        <Divider sx={{ mt: 0.5, mb: 1 }} key={key + "divider"} />
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography className="detail-value" align="center" key={key + "bText"}>
                                            {value}
                                        </Typography>
                                        </Box>
                                    </Grid>))
                                }
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {/* Lower Content Box */}
                        <Box ref={boxBRef} sx={{ backgroundColor: '#F8F8F8', padding: '24px' }}>
                            {/* Original Query */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={gridSize}>
                                    <Grid container>
                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', }}>
                                            <Typography sx={{ ml: { xs: 2, sm: 8 }, mb: 1 }} className="detail-heading">
                                                Case Communications
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                                            {!showNotes && <Button onClick={handleNotes} sx={{ mr: { xs: 2, sm: 8 } }} className="button-text">{notesButton}</Button>}
                                        </Grid>
                                    </Grid>
                                    <Card sx={{ mb: 3, ml: { xs: 2, sm: 8 }, mr: { xs: 2, sm: 8 }, maxHeight: { xs: "50vw", sm: "25vw" }, mt: 1, overflow: "auto" }}
                                        className="detail-component-card">
                                        {orgQuery.map((text, index) => (
                                            <>
                                                <Typography className="detail-query" padding={"10px"} sx={{ ml: 3 }}>
                                                    {index != 0 && <Divider sx={{ mb: 1 }} />}
                                                    {text}
                                                </Typography>
                                            </>
                                        ))}
                                    </Card>
                                </Grid>
                                {showNotes &&
                                    <Grid item xs={12} sm={12 - gridSize}>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <Typography sx={{ ml: { xs: 1, sm: 4 }, mb: 1 }} className="detail-heading">
                                                    Notes
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                                                <Button onClick={handleNotes} className="button-text" sx={{ mr: { xs: 2, sm: 8 } }}>{notesButton}</Button>
                                            </Grid>
                                        </Grid>
                                        <Card sx={{
                                            ml: { xs: 1, sm: 1 }, mr: { xs: 2, sm: 8 }, overflow: "auto", maxHeight: { xs: "50vw", sm: "15vw" },
                                            borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 15, borderTopRightRadius: 15
                                        }}>
                                            {notes.map((text, index) => (
                                                <>
                                                    <Typography className="detail-query" padding={"10px"} sx={{ ml: 3 }}>
                                                        {index != 0 && <Divider sx={{ mb: 2 }} />}
                                                        {text}
                                                    </Typography>
                                                </>
                                            ))}
                                        </Card>
                                        <Card aria-label="New Note" label="label" sx={{
                                            backgroundColor: "#dddddd", mb: 3, ml: { xs: 1, sm: 1 }, mr: { xs: 2, sm: 8 }, padding: "15px",
                                            borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 15, borderBottomRightRadius: 15
                                        }}>
                                            <Box sx={{ display: 'flex', alignItems: 'end' }}>
                                                <TextField sx={{ display: "flex" }} label="New Note" fullWidth aria-label="Enter Message" />
                                                <IconButton aria-label="Send Button"
                                                    sx={{
                                                        backgroundColor: '#FFFFFF',
                                                        color: '#003057',
                                                        borderRadius: "4px",
                                                        border: "2px solid",
                                                        ml: 1,
                                                    }}>
                                                    <Send />
                                                </IconButton>
                                            </Box>
                                        </Card>
                                    </Grid>}
                            </Grid>
                            {/* Your Response */}
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <Card sx={{ mb: 3, ml: { xs: 2, sm: 8 }, mr: { xs: 2, sm: 8 }, backgroundColor: "#EAF6F3" }} className="detail-component-card">
                                        <Typography className="detail-heading" gutterBottom padding={"10px"}>Your Response</Typography>
                                        <Box sx={{ ml: 3, mr: 3, backgroundColor: "#FFFFFF" }}>
                                            <TextField sx={{ display: "flex" }} label="Response" />
                                        </Box>
                                        <Box display="flex" justifyContent="flex-end" sx={{ padding: 2, mr: 1 }}>
                                            <Button variant="outline" startIcon={<BookmarkBorderOutlined />}
                                                sx={{
                                                    border: '2px solid',
                                                    backgroundColor: '#FFFFFF',
                                                    color: '#000000',
                                                    mr: 1
                                                }}>
                                                Save
                                            </Button>
                                            <Button variant="contained" startIcon={<Send />}
                                                sx={{
                                                    backgroundColor: '#007FA3',
                                                    color: '#FFFFFF'
                                                }}>
                                                Send
                                            </Button>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            </Grid>
        </Grid>
        </Box>

            <Dialog open={open} onClose={handleClickEscalate}>
                <DialogTitle>Who would you like to escalate to?</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="dropdown-label">Role</InputLabel>
                        <Select
                            labelId="dropdown-label"
                            id="dropdown-select"
                            label="Choose an Option"
                        >
                            <MenuItem value={10}>Supervisor</MenuItem>
                            <MenuItem value={20}>Principle</MenuItem>
                            <MenuItem value={30}>Chair</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="dropdown-label2">Name</InputLabel>
                        <Select
                            labelId="dropdown-label2"
                            id="dropdown-select2"
                            label="Choose an Option"
                        >
                            <MenuItem value={10}>Arya Stark</MenuItem>
                            <MenuItem value={20}>Alicent H.</MenuItem>
                            <MenuItem value={30}>Baela T.</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickEscalate} color="primary">
                        Cancel Escalation
                    </Button>
                    <Button onClick={handleClickEscalate} color="primary" autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={release} onClose={handleClickRelease}>
                <DialogTitle>Why would you like to release the case?</DialogTitle>
                <DialogContent>
                    <TextField sx={{ display: 'flex', mb: 1, mt: 2 }} label="Reason"></TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickRelease} color="primary">
                        Cancel Release
                    </Button>
                    <Button onClick={handleClickRelease} color="primary" autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Alternate;