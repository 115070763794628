export const defaultValues = {
  CaseQuery: "",
  Queue: "",
  QueryType: "",
  LearnerWorkInteractionType: "",
  EPENDocId: "",
  Centre: "",
  assignToRole: "",
  assignToUser: "",

  availability_window_short_name: "",
  qualification_type_title: "",
  qualification_id: "",
  paper_id: "",
  paper_title: "",
};

export const learnerWork = [
  { key: "EPEN", value: "EPEN" },
  { key: "LWA", value: "Learner Work Assessment" },
];

export const otherUserQueryTypeData = [
  {
    key: "1",
    value:
      "I would like you to complete a Sample and/or give feedback on the sample completed.",
  },
  {
    key: "2",
    value: "I would like to update you on the application of the Mark Scheme",
  },
  {
    key: "3",
    value: "I would like to check on your marking progress",
  },
  {
    key: "4",
    value: "I would like to provide feedback/update you on something else",
  },
];

export const markerQueryTypeData = [
  {
    key: "1",
    value: "I have completed my Sample and would like my marks reviewed",
  },
  {
    key: "2",
    value:
      "I would like to check a safeguarding concern and/or have guidance on how to escalate this to Pearson",
  },
  { key: "3", value: "I need help applying the mark scheme" },
  { key: "4", value: "I need help understanding a learner response" },
  { key: "5", value: "I would like to update you on my progress" },
  {
    key: "6",
    value:
      "I would like to check a malpractice concern and/or have guidance on how to escalate this to Pearson",
  },
  { key: "7", value: "I have a query about something else " },
];

export const visibleDocIdQueryType = ["2", "4", "6"];
export const visibleCenterNumberQueryType = ["1", "2", "4", "6"];

export const centers = [
  {
    centerNumber: 212323323,
    centerName: "Test",
  },
  {
    centerNumber: 333333122,
    centerName: "Test3",
  },
  {
    centerNumber: 23232323,
    centerName: "Test3",
  },
  {
    centerNumber: 4456656,
    centerName: "Test4",
  },
];

export const rolesHierarchy = [
  { level: 1, role: "internal" },
  { level: 2, role: "chair" },
  { level: 3, role: "principal" },
  { level: 4, role: "supervisor" },
  { level: 5, role: "marker" },
];
