import axios from "axios";
import { apiBaseUrl } from "../config/constants";

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //setLoading(true)
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (config) {
    // Do something before request is sent
    //setLoading(false)
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
