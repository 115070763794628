import { useNavigate, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import CaseDetail from "./CaseDetail"
import CaseDetailSupervisor from "./CaseDetailSupervisor"
import CaseDetailInternal from "./CaseDetailInternal"
import ChooseRole from "../authentication/ChooseRole"

const CaseRouter = () => {
    const userData = useSelector((state) => state.user);
    const role = userData.user.currentRole;
    const navigate = useNavigate()
    if (!role) {
        navigate('/')
        return
    }
    switch (role.toLowerCase()) {
        case 'marker':
            return <CaseDetail />
        case 'supervisor':
            return <CaseDetailSupervisor />
        case 'principal':
            return <CaseDetailSupervisor />
        case 'chair':
            return <CaseDetailSupervisor />
        case 'internal':
            return <CaseDetailInternal />
        default:
            return <ChooseRole />
    }
}

export default CaseRouter