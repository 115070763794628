import {
  Typography,
  Avatar,
  Box,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../helpers/utils";

function UserInfo({ name, role }) {
  return (
    <Card sx={{ display: "flex", backgroundColor: "#FAFAFA" }}>
      {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}></CardContent>
      </Box> */}

      <CardContent sx={{ flex: "1 0 auto" }}>
        <Typography component="div" variant="h5" color="#003057">
          Name
        </Typography>
        <Divider />
        <Typography
          // display="inline"
          // component="div"
          className="detail-value"
          sx={{mb: 2, wordWrap: 'break-word',}}
        >
          {name}
        </Typography>
        <Typography component="div" variant="h5" color="#003057">
          Role
        </Typography>
        <Divider />
        <Typography className="detail-value">
          {capitalizeFirstLetter(role)}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default UserInfo;
