import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/http";

export const createCase = createAsyncThunk(
  "createCase",
  async (data, thunkApi) => {
    try {
      const stateData = thunkApi.getState();
      const currentUserRole = stateData?.user?.user?.currentRole.toLowerCase();
      const userToken = stateData.user.user.accessToken;
      const result = await axiosInstance.post(`/case`, JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Caller-Role": `${currentUserRole}`,
        },
      });
      return result.data;
    } catch (error) {
      console.log('error:::', error.response.status);
      const responseStatus = error?.response?.status;
      let errorMessage = '';
      switch (responseStatus) {
        case 400:
          errorMessage = error?.response?.data?.message;
          break;

        default:
          errorMessage = "Error while creating a case. Try again later."
          break;
      }
      return thunkApi.rejectWithValue({
        message: errorMessage,
      });
    }
  }
);

const initialState = {
  cases: [],
  isLoading: false,
  isError: false,
};

export const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Post api case Data
    builder.addCase(createCase.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createCase.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cases.push(action.meta.arg);
      state.successResponse = action.payload;
    });
    builder.addCase(createCase.rejected, (state, action) => {
      state.isError = true;
      state.errorMsg =
        action.payload || `Error while creating a case. Try again later.`;
    });
  },
});
// Action creators are generated for each case reducer function
//export const { createCase } = caseSlice.actions;

export default caseSlice.reducer;
