export const ROLES = {
  marker: "marker",
  supervisor: "supervisor",
  Principal: "principal",
  Chair: "chair",
  Internal: "internal"
};

export const SCOPES = {
  marker: "marker",
  supervisor: "supervisor",
  principal: "principal",
  chair: "chair",
  internal: "internal"
};

export const PERMISSIONS = {
  [ROLES.marker]: [SCOPES.marker],
  [ROLES.supervisor]: [SCOPES.supervisor],
  [ROLES.principal]: [SCOPES.principal],
  [ROLES.chair]: [SCOPES.chair],
  [ROLES.internal]: [SCOPES.internal],
};
