import { createSlice } from "@reduxjs/toolkit";
//import { PURGE } from 'redux-persist';

//import { persistor } from "../store";

const initialState = {
  user: {
    accessToken: null,
    idToken: null,
    refreshToken: null,
    isAuthenticated: false,
    userType: "UNAUTHORIZED",
    userId: "",
    userName: "",
    firstName: "",
    lastName: "",
    aaNumber: "",
    currentRole : "",
    
  },
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const obj = {
        ...action.payload,
      };
      state.user = obj;
    },
    setCurrentRole: (state, action) => {
      state.user.currentRole = action.payload
    },
    logout: (state, action) => {
      localStorage.removeItem("persist:root");
      localStorage.removeItem("loglevel");
      localStorage.clear();
      // window.location.assign(logoutUrl);
      //state.user=null;

      //   persistor.pause();
      // persistor.flush().then(() => {
      //   return persistor.purge();
      // });
    },
  },
});

export const { setCredentials, logout, setCurrentRole } = authSlice.actions;

export default authSlice.reducer;
