import React, { useState, useEffect } from "react";
import { Card, CardHeader } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";

import {
  Button,
  Typography,
  Box,
  AppBar,
  Grid,
  Toolbar,
  Divider,
  Paper,
} from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";
import {
  ArrowBack,
  ArrowForward,
  BookmarkBorderOutlined,
  Info,
  Send,
  Warning,
} from "@mui/icons-material";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function Casecard({ caseDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const caseNumber = caseDetails.CaseNumber;
  // delete caseDetails['CaseNumber'];
  const caseDetailDict = caseDetails;

  return (
    <>
      <Paper>
        <Box
          sx={{
            backgroundColor: "#003057",
            padding: "10px 0px",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            padding: "15px",
            ml: { xs: 2, sm: 8 },
          }}
        >
          <Typography className="detail-title" gutterBottom>
            Case {caseNumber}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            padding: "15px",
            ml: { xs: 2, sm: 8 },
            display: "flex",
          }}
         >
          <Grid container spacing={4}>
            {Object.entries(caseDetailDict).map(([key, value]) => (
              <Grid
                item
                xs={isMobile ? 12 : 12 / caseDetailDict}
                sm={12 / caseDetailDict}
                md={12 / caseDetailDict}
                lg={12 / caseDetailDict}
                key={key}
              >
                {!(key === "CaseNumber") ? (
                  <>
                    <Typography
                      className="detail-key"
                      align="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      {key}
                    </Typography>
                    <Divider sx={{ mt: 0.5, mb: 1 }} />
                    <Typography className="detail-value" align="center">
                      {value}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

export default Casecard;
